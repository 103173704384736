import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'antd';
import { setActiveModel } from '../../data/store/instruments/instrumentsActions';

const ModalActivateModel = ({modalActivateModel, handleActivateClose}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const { modelList } = useSelector((state) => state.instrumentsReducer);
  // const [errorMessage, setErrorMessage ] = useState(false);

  const getModelById = () => {
    return modelList?.find(i => i.id === modalActivateModel)?.name;
  };

  // const handleErrorMessage = () => {
  //   setErrorMessage('Error! Cannot delete model');
  //   setTimeout(() => {
  //     setErrorMessage(false);
  //   }, 2500);
  // };

  const handleSubmit = () => {
    dispatch(setActiveModel({id: modalActivateModel, callback: handleActivateClose}));
  };

  return (
    <Modal
        width={650}
        className="modal-content-paper"
        open={modalActivateModel}
        onCancel={handleActivateClose}
        footer={null}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('CONFIRM_ACTIVATION')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('MODAL_ACTIVATE_MODAL')}</p>
            <p className="modal-advisor-name">{getModelById()}</p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={handleActivateClose}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={handleSubmit}>
            {t('ACTIVATE')}
          </Button>
        </Row>
      </Modal>
  );
};

export default ModalActivateModel;

ModalActivateModel.propTypes = {
  modalActivateModel: PropTypes.number,
  handleActivateClose: PropTypes.func,
};
