import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useHistory, useParams, useLocation } from 'react-router-dom';

import Container from '../../components/Container';
import { Button, Col, Layout, Row, Select } from 'antd';
import MainFooter from '../../components/MainFooter';

import { useQuery } from '../../hooks/useQuery';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearModel,
  getModelById,
  getModelSeries,
} from '../../data/store/instruments/instrumentsActions';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { SortArrow } from '../Clients/ClientSummary';

import { textSort } from '../../helpers/textSort';
import { monthList } from '../../constants/monthList';
import './style.scss';
import { mockPrimitives } from '../../mocks/mockPrimitives';

const { Header } = Layout;
const ModelItemView = () => {
  const { t, i18n } = useTranslation('');
  const history = useHistory();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const query = useQuery(search);
  const { itemKey } = useParams();

  const [primitivesType, setPrimitivesType] = useState('1');
  const [primitivesSet, setPrimitivesSet] = useState(null);

  const meanReturnOptions = [
    { value: '1', label: t('MEAN_LINEAR_RETURN') },
    { value: '2', label: t('MEAN_LOG_RETURN') },
    { value: '3', label: t('VARIANCE') },
    { value: '4', label: t('STANDARD_DEV_VOLATILITY') },
    { value: '61', label: t('WEIGHT_RANGE_VOLATILITY_ADJUSTMENT') },
  ];

  const assets = useSelector((state) => state.instrumentsReducer.assets);
  const model = useSelector((state) => state.instrumentsReducer.modelById);
  const modelSeries = useSelector(
    (state) => state.instrumentsReducer.modelSeries,
  );

  const [series, setSeries] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [assetSelectOptions, setAssetSelectOptions] = useState(null);

  const [sortValue, setSortValue] = useState(null);
  const [sortClass, setSortClass] = useState(null);

  const language = i18n.languages?.length ? i18n.languages[0] : 'en';
  const currentLang = ['en', 'sw'].includes(language) ? language : 'en';

  const tab = query.get('tab');
  const asset = query.get('asset');
  const primitive = query.get('primitive');

  useEffect(() => {
    if (itemKey) {
      dispatch(getModelById(itemKey));
      !tab && history.replace(`${pathname}?tab=index-series`);
    }
    return () => dispatch(clearModel());
  }, [itemKey]);

  useEffect(() => {
    if (asset && assetSelectOptions) {
      setSelectedOptions(+asset);
    } else if (!asset && assetSelectOptions && selectedOptions === null) {
      const assetSort = assetSelectOptions.sort((a, b) =>
        textSort(a.name, b.name, 1),
      );
      const firstAsset = assetSort ? assetSort[0]?.id || 0 : 0;
      history.replace(`?tab=index-series&asset=${firstAsset}`);
    }
  }, [asset, assetSelectOptions]);
  
  useEffect(() => {
    if (primitive) {
      const data = mockPrimitives?.find((i) => i.type === +primitive);
      setPrimitivesSet(data);
      setPrimitivesType(primitive);
    }else if (!primitive && tab === 'model-primitives') {
      history.push('?tab=model-primitives&primitive=1');
    }
  }, [primitive, tab]);

  useEffect(() => {
    if (assets) {
      const options = assets.map((i) => {
        const data = {
          id: i.ID,
          name: i.name,
          status: getStatusAsset(i.ID),
        };
        return data;
      });
      setAssetSelectOptions(options);
    }
  }, [assets, series]);

  useEffect(() => {
    if (modelSeries?.series) {
      setSeries(modelSeries.series);
    }
    if (!modelSeries?.series && model?.series_set_id) {
      dispatch(
        getModelSeries({ id: itemKey, seriesSetId: model.series_set_id }),
      );
    }
  }, [model, modelSeries]);


  const getStatusAsset = useCallback(
    (id) => {
      if (series?.length) {
        console.log('🚀 ~ ModelItemView ~ series:', series);
        const assetDataForValidate = series.find(
          (i) => i.asset_class_id === id,
        );
        const result = [];

        assetDataForValidate?.values?.forEach((i) => {
          const array = Object.values(i.data);
          result.push(...array.filter((i) => i !== null));
        });

        return result.every((i) => !!i || i === 0)
          ? 'full'
          : result.every((i) => i === '')
            ? 'blank'
            : 'partially';
      }
      return 'blank';
    },
    [series],
  );

  const handleChangeOption = (value) => {
    history.push(`?tab=${tab}&primitive=${value}`);
  };

  const getAssetById = (id) => {
    const asset = assets.find(asset => asset.ID === id);
    return {name: asset?.name ?? '', category: asset?.financialAssetCategory.name ?? ''};
  };

  const handleSort = (coll) => {
    if (coll === 'value') {
      setSortClass(null);
      if (sortValue !== null) {
        if (sortValue) {
          setSortValue(false);
        } else {
          setSortValue(null);
        }
      } else {
        setSortValue(true);
      }
    } else if (coll === 'class') {
      setSortValue(null);
      if (sortClass !== null) {
        if (!sortClass) {
          setSortClass(true);
        } else {
          setSortClass(null);
        }
      } else {
        setSortClass(false);
      }
    }
  };

  const handleEditModel = () => {
    history.push(`/quant/model/item/${itemKey}`);
  };

  const handleChangeTab = (path) => {
    history.push(`${pathname}?tab=${path}`);
  };

  const handleChangeAssets = (value) => {
    history.push(`?tab=${tab}&asset=${value}`);
  };

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
      <div>
        <Header className="headerStyle">
          <Row className="overview-tabs-wrapper">
            <Col className="tabs-tab-wrapper" span={18}>
              <Button type="link" className="overview-btn ant-btn-active">
                {t('MODEL')}
              </Button>
            </Col>
          </Row>
          <div className="top-btn-wrapper">
            <Button
              onClick={handleEditModel}
              className="portfolio-details-btn optimise-btn"
            >
              {t('EDIT')}
            </Button>
          </div>
        </Header>
        <h2 style={{ marginTop: '30px' }} className="out-container-title">
          {' '}
          {t('MODEL')} {model?.name ?? 'not found'}
        </h2>
        <Row justify={'center'} align={'center'} className="model-tabs-wrapper">
          <Col className="model-tabs-column">
            <div
              onClick={() => handleChangeTab('index-series')}
              className={`model-tab-btn-wrapper ${tab === 'index-series' ? 'selected' : ''}`}
            >
              <p className="model-tab-btn-title">{t('INDEX_SERIES')}</p>
              <div className="model-tab-btn-round" />
            </div>
          </Col>
          <Col className="model-tabs-column">
            <div
              onClick={() => handleChangeTab('model-primitives')}
              className={`model-tab-btn-wrapper ${tab === 'model-primitives' ? 'selected' : ''}`}
            >
              <p className="model-tab-btn-title">{t('MODEL_PRIMITIVES')}</p>
              <div className="model-tab-btn-round" />
            </div>
          </Col>
        </Row>
        <h2 className="out-container-title">
          {tab === 'index-series' ? t('INDEX_SERIES') : t('MODEL_PRIMITIVES')}
        </h2>
        <div style={{ padding: '0 24px' }}>
          <Container widthP={100} mb="24px" padding="0 0 24px">
            {tab === 'index-series' ? (
              <>
                <Row justify={'start'} style={{ padding: '24px' }}>
                  <CustomSelect
                    placeholder={t('SELECT_ASSET_CLASS')}
                    className="custom-select asset-select-models"
                    onChange={(value) => handleChangeAssets(value)}
                    value={selectedOptions}
                  >
                    {assetSelectOptions
                      ?.slice()
                      ?.sort((a, b) => textSort(a.name, b.name, 1))
                      .map((item) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            <div
                              className={`asset-option-status ${item.status}`}
                            />
                            <div className="">{item.name}</div>
                          </Select.Option>
                        );
                      })}
                  </CustomSelect>
                </Row>
                <Row justify={'space-between'} className="model-series-headers">
                  <Col className="model-series-headers-cell"> </Col>
                  {monthList[currentLang].map((i) => (
                    <Col key={i} className="model-series-headers-cell">
                      {i}
                    </Col>
                  ))}
                </Row>
                {series &&
                  series
                    ?.find((i) => i.asset_class_id === selectedOptions)
                    ?.values.map((i, index) => {
                      return (
                        <Row
                          key={i.year}
                          className={`model-series-row ${index % 2 !== 0 && 'is-odd'}`}
                        >
                          <Col className="model-series-row-cell">{i.year}</Col>
                          {Object.keys(i.data).map((month) => (
                            <Col
                              key={month}
                              className={'model-series-row-cell'}
                            >
                              {i.data[month] !== null ? (
                                <span className="series-data-cell">
                                  {i.data[month].toFixed(2)}
                                </span>
                              ) : null}
                            </Col>
                          ))}
                        </Row>
                      );
                    })}
              </>
            ) : (
              <>
                <Row justify={'start'} style={{ padding: '24px' }}>
                  <CustomSelect
                    placeholder={t('SELECT_ASSET_CLASS')}
                    className="custom-select asset-select-models"
                    options={meanReturnOptions}
                    value={primitivesType}
                    onSelect={(value) => handleChangeOption(value)}
                  />
                </Row>
                <Row wrap={false} className="primitives-header">
                  <Col
                    span={primitivesType === '61' ? 9 : 12}
                    className="primitives-title-class"
                  >
                    {t('ASSET_CLASS')}
                    <div onClick={() => handleSort('class')}>
                      <SortArrow
                        direction={
                          sortClass ? 'up' : sortClass !== null ? 'down' : ''
                        }
                      />
                    </div>
                  </Col>
                  {primitivesType === '61' ? (
                    <>
                      <Col span={5} className="primitives-title-value">
                        {t('p1')}
                        <div onClick={() => handleSort('value')}>
                          <SortArrow
                            direction={
                              sortValue
                                ? 'up'
                                : sortValue !== null
                                  ? 'down'
                                  : ''
                            }
                          />
                        </div>
                      </Col>
                      <Col span={5} className="primitives-title-value">
                        {t('p2')}
                        <div onClick={() => handleSort('value')}>
                          <SortArrow
                            direction={
                              sortValue
                                ? 'up'
                                : sortValue !== null
                                  ? 'down'
                                  : ''
                            }
                          />
                        </div>
                      </Col>
                      <Col span={5} className="primitives-title-value">
                        {t('VALUE')}
                        <div onClick={() => handleSort('value')}>
                          <SortArrow
                            direction={
                              sortValue
                                ? 'up'
                                : sortValue !== null
                                  ? 'down'
                                  : ''
                            }
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={12} className="primitives-header-value">
                        {t('QUANT_VALUE')}
                        <div onClick={() => handleSort('value')}>
                          <SortArrow
                            direction={
                              sortValue
                                ? 'up'
                                : sortValue !== null
                                  ? 'down'
                                  : ''
                            }
                          />
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
                {primitivesSet &&
                  primitivesSet?.mean_return?.map((el, index) => {
                    return (
                      <Row
                        key={el.asset_class_id}
                        wrap={false}
                        className={`primitives-row ${index % 2 === 0 ? '' : 'is-odd'}`}
                      >
                        <Col
                          span={primitivesType === '61' ? 9 : 12}
                          className="primitives-title-class"
                        >
                          <div className="">
                            <p className="primitives-cell-name">
                              {getAssetById(el.asset_class_id)?.name}
                            </p>
                            <p className="primitives-cell-category">
                              {getAssetById(el.asset_class_id)?.category}
                            </p>
                          </div>
                        </Col>
                        {primitivesType === '61' ? (
                          <>
                            <Col
                              span={5}
                              style={{ marginLeft: '-12px' }}
                              className="primitives-title-value"
                            >
                              <div className="">{el?.quant_value?.p1}</div>
                            </Col>
                            <Col
                              span={5}
                              style={{ marginLeft: '-6px' }}
                              className="primitives-title-value"
                            >
                              <div className="">{el?.quant_value?.p2}</div>
                            </Col>
                            <Col
                              span={5}
                              style={{ marginLeft: '-8px' }}
                              className="primitives-title-value"
                            >
                              <div className="">{el?.quant_value?.value}</div>
                            </Col>
                          </>
                        ) : primitivesType !== '61'
                          ? (
                            <>
                              <Col
                                span={12}
                                className="primitives-heaer-value"
                              >
                                <div className="">{el?.quant_value}</div>
                              </Col>
                            </>
                            )
                          : null
                        }
                      </Row>
                    );
                  })}
              </>
            )}
          </Container>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default ModelItemView;
